<template>
  <NuxtPage />
  <NuxtLayout />
  <ClientOnly>
    <GlobalDialog />
    <NewkiPopup />
    <!-- <NewkiFPS v-if="showFPS" /> -->
  </ClientOnly>
</template>
<script setup lang="ts">
import { ref, computed, watch, watchEffect, onMounted, onBeforeMount, nextTick } from 'vue'
import { apiMatchSite } from '~/service/api/common'
import { useSystemStore } from '~/store/system'
import { useCartStore } from '~/store/cart'
import { useMemberStore } from '~/store/member'
import { SetSiteInfo } from '~/composables/setSiteInfo'
import type { IString } from '~/types/IString'
import { useSetMemberInfo } from '~/composables/useSetMemberInfo'
import { apiGetCart, apiNotLoginAdd } from '~/service/api/cart'
import { getPlatForm } from '~/utils/getPlatForm'
import NewkiPopup from '~/components/NewkiPopup.vue'
import { useBroswerStore } from '~/store/broswer'
import { useDocumentVisibility } from '@vueuse/core'
import { useGetBetterPrice } from '~/store/getBetterPrice'
const { siteList } = storeToRefs(useSystemStore())
const { t } = useI18n()
const { setIsSupportsWebP, setBroserInfo } = useBroswerStore()
const { setCartData } = useCartStore()
const { setAgreeAgreement } = useMemberStore()
const { currentSite } = storeToRefs(useSystemStore())
const { setPageVisibility, getSystemConfig, querySite, toggleSiteSelectShow } = useSystemStore()
const { setGetMoreFlag, setPageCode } = useGetBetterPrice()

useResizeEvent()

// 页面可见情况
const pageVisibility = useDocumentVisibility()

const route = useRoute()
const router = useRouter()

type cookiesLanguage = IString | null | undefined

// 當前的路由名稱
const routeName = ref(route.name)

const showFPS = ref(import.meta.env.DEV)

useHead({
  bodyAttrs: {
    id: 'pc-container',
  },
})

watch(
  () => route.hash.error,
  val => {
    if (val === '#ecode-500') {
      console.log('watch：#ecode-500')
    }
  },
  { immediate: true }
)

watch(
  pageVisibility,
  val => {
    let state = val === 'hidden' ? false : true
    setPageVisibility(state)
  },
  { immediate: true }
)

watch(
  () => useIsLogin(),
  val => {
    if (val) {
      const timer = setTimeout(() => {
        // 延時等待註冊活動先請求
        clearTimeout(timer)
        loginActivity()
      }, 3000)
    }
  },
  { immediate: true, deep: true }
)

watchEffect(async () => {
  if (currentSite.value) {
    // 宣傳頁不需要發起 apiGetCart，在組件內實現
    // if (routeName.value?.includes('promotion-code')) return false
    if (useIsLogin() && isClient()) {
      // 站點改變請求購物車數據
      let data = await apiGetCart()
      if (data) {
        setCartData(data)
      }
    }
  }
})

const isWechat = computed(() => {
  return getPlatForm() === 'WeChat'
})

const _matchSite = async () => {
  // setGreyCookies()
  const newkiSite = useCookie('newki-site').value || ''
  const newkiLanguage = (useCookie('newki-language').value as cookiesLanguage) || {}
  const newkiSendway = useCookie('newki-sendWay').value || ''
  let language = newkiLanguage[newkiSite]
  // 是否有本地存儲的默認站點和該站點下的默認語言語言
  // 首次訪問時已由node服務請求matchSite 設置cookies
  if (newkiSite && language) {
    SetSiteInfo(newkiSite, language, newkiSendway)
  } else {
    const data = await apiMatchSite()
    if (data && data.siteId && data.languageCode) {
      SetSiteInfo(data.siteId, data.languageCode, data.settlementWay)
    }
  }
}

const getMemberInfo = async () => {
  const token = useCookie('token').value
  if (!token) return
  useSetMemberInfo()
}

// 設置灰度測試cookies
const setGreyCookies = () => {

  if (route.query['ftofs_grey'] && route.query['ftofs_grey'] === 'true') {
    useCookie('ftofs_grey', { domain: '' }).value = 'true'
  } else {
    useCookie('ftofs_grey').value = ''
  }
}
// 設置本地購物車
async function setKLocalCart() {
  if (useIsLogin()) return
  let localCart = useLocalStorage('local-cart', '')
  if (!localCart.value) return
  let data = await apiNotLoginAdd(JSON.parse(localCart.value))
  if (data) {
    setCartData(data)
  }
}

// 頁面刷新時需要清除的緩存
function clearCache() {
  let preAddress = useLocalStorage('newki-current-address', '')
  preAddress.value = ''
}

// 登錄活動
function loginActivity() {
  console.log('loginTriggerActivity')
  setGetMoreFlag(true)
  setPageCode('loginTriggerActivity')
}

if (!import.meta.env.SSR) {
  _matchSite()
  getSystemConfig()
  getMemberInfo()
  // if (!routeName.value?.includes('promotion-code')) {
  //   setKLocalCart()
  // }
  setKLocalCart()
  clearCache()
  if (isWechat.value) {
    setAgreeAgreement(true)
  }
  setIsSupportsWebP()
  setBroserInfo()
}

// 获取并设置站点信息
async function setSite(show: boolean = false) {
  let data = await querySite()
  if (data.list) {
    toggleSiteSelectShow(show)
  }
}

onMounted(() => {
  useLog()
  setSite()
  useComputedFontSize()
})

onBeforeMount(() => {
  sessionStorage.removeItem('pagePosition')
  sessionStorage.removeItem('routeFrom')
})
</script>

<style lang="scss">
@media screen and (min-width: 480px) {
  @mixin center($w: 400px, $left: 0, $right: 0) {
    width: $w;
    left: $left;
    right: $right;
    margin: 0 auto;
  }

  #pc-container {
    width: 400px;
    height: 100vh;
    margin: 0 auto;
    overflow: hidden;

    .bottom-toolbar-container, // 购物车结算
    .nav-bar-widget, // 頂部导航
    .nav-bar-container, // 底部导航
    .detail-btn-group, // 訂單詳情
    .pay-btn, // 支付
    .bottom-container, // 聊天底部
    .bottom-bar, // 訂單確認頁
    .comment-input-box, // 評論
    .bottom-line, //底部線條
    .address-btn-container, // 地址底部按鈕
    .img-cropper-container, // 頭像裁剪
    .share-btn-container, // 分享頁面
    .aftersale-action-box, // 售後服務詳情頁面，底部按鈕欄
    .guide-title, // 使用说明
    .post-header-widget, // 文章详情
    .header-widget,
    .newki-buy-vip,
    .navigation-box,
    .cabinet-save-btn-box,
    .cabinet-next-btn-box {
      @include center();
    }

    .banner-container {
      .van-swipe-item {
        width: 400px;
        height: 400px;

        &.video {
          height: 500px;
        }
      }
    }

    .banner-container-holder {
      width: 400px;
      height: 404px;
    }

    .newki-entry {
      @include center();
      @include flex(row, flex-end, center);
      display: none;

      &.show {
        display: block;
      }

      button {
        position: relative;
        right: 10px;
      }
    }

    // 登录框
    .van-popup.login-dialog-float.login-dialog-widget {
      @include center(390px);
      .newki-line.bottom-line {
        width: 100%;
      }
    }

    // email 输入框
    .van-popup.email-dialog-float.email-dialog-widget {
      @include center(390px);
      .newki-line.bottom-line {
        width: 100%;
      }
    }

    // 登录悬浮按钮
    .login-float-btn-container {
      @include center($w: 390px);
    }

    // 除了 toast 之外的所有 popup
    .van-popup {
      &:not(.van-toast, .newki-loading, .van-popover) {
        @include center();
      }
    }

    .newki-popup.float-btn-container {
      right: calc(50vw - 200px);
      transform: translateX(calc(50% + 8px));

      &.show {
        transform: translateX(-8px);
      }
    }
  }
}
</style>
<style>
.page-enter-active,
.page-leave-active {
  transition: opacity 0.1s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  /* filter: blur(1rem); */
}
</style>
