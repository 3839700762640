<template>
  <div id="messageList" class="chat-box-container">
    <InfiniteLoading @infinite="load" :identifier="infiniteId" :top="true" :firstload="true" target="#messageList">
      <template #complete>
        <div class="no-message">{{ t('chat.noMoreMsg') }}</div>
      </template>
      <template #spinner>
        <div class="loading no-message">{{ t('chat.loading') }}</div>
      </template>
      <template #error>
        <div class="no-message">{{ t('chat.error') }}</div>
      </template>
    </InfiniteLoading>
    <div
      class="message-item"
      :class="item.msgType === 2 ? 'message-left' : 'message-right'"
      v-for="(item, index) in msgList"
      :key="index"
    >
      <template v-if="item.msgType === 1">
        <NewkiImage
          class="avatar"
          v-if="memberInfo.avatar"
          :src="memberInfo.avatar"
          :width="36"
          :height="36"
          radius="50%"
        />
        <img v-else class="avatar" src="~/assets/images/login/newki-open.svg" width="36" height="44" />
      </template>
      <div v-else class="avatar">
        <img src="~/assets/images/login/newki-open.svg" width="36" height="44" />
      </div>
      <div class="content-container">
        <template v-for="(cItem, CIndex) in item.msgContent">
          <div class="time" :class="item.msgType === 1 ? 'time-right' : ''">{{ dealTime(item.msgTime) }}</div>
          <div class="content" :class="{ 'other-content': cItem.type !== 'text' }">
            <div class="msg-error" v-if="cItem.status === false" @click="tip(cItem.error ? cItem.error : '')">
              <van-icon name="warning" size="14" color="#ff3a3d" />
            </div>
            <span v-if="cItem.type === 'text' && !cItem.code">{{ cItem.content }}</span>
            <NewkiImage
              v-else-if="cItem.type === 'image'"
              :src="cItem.content"
              @click="imageView(cItem.content)"
              block
              width="375"
              height="375"
              :radius="12"
            />
            <div class="video-container" v-else-if="cItem.type === 'video'">
              <NewkiVideo :url="cItem.content" block :fullscreen="true" />
            </div>
            <Product
              v-else-if="cItem.code === 'dynamicTemplates' && cItem.type === 'system-product'"
              :params="cItem.content"
              @close="closeModal"
            />
            <Order
              v-else-if="cItem.code === 'dynamicTemplates' && cItem.type === 'system-order'"
              :params="cItem.content"
              @close="closeModal"
            />
            <Button
              v-else-if="cItem.code === 'dynamicTemplates' && cItem.type === 'template'"
              :content="JSON.parse(cItem.content)"
            />
            <Text v-else-if="cItem.code === 'dynamicTemplates' && cItem.type === 'text'" :text="cItem.content" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch, onMounted } from 'vue'
import InfiniteLoading from 'v3-infinite-loading'
import { useMemberStore } from '~/store/member'
import { useImStore } from '~/store/im'
import { showFailToast, showImagePreview } from 'vant'
import { apiGetHistory } from '@/service/api/chat'
import dayjs from 'dayjs'
import Product from '@/components/chat/Product.vue'
import Order from '@/components/chat/Order.vue'
import Button from '@/components/chat/Button.vue'
import Text from '@/components/chat/Text.vue'
import NewkiImage from '~/components/NewkiImage.vue'
import isEmpty from 'lodash/isEmpty'

const { t } = useI18n()
const infiniteId = ref(+new Date())
const { memberInfo, visitorId } = storeToRefs(useMemberStore())
const { newMessage } = storeToRefs(useImStore())
const { clearNewMessage } = useImStore()
const msgList = ref<any>([])
const pageNum = ref(1)
const pageSize = ref(10)
const total = ref(0)
const emit = defineEmits(['close'])

onMounted(() => {
  const timer = setTimeout(() => {
    clearTimeout(timer)
    scrollBottom()
  }, 500)
})

watch(
  () => newMessage.value,
  (val: any) => {
    if (!isEmpty(val)) {
      msgList.value.push({
        msgType: val.lastMsgType,
        msgTime: val.lastMsgTime,
        msgContent: JSON.parse(val.lastMsgContent),
      })
      const timer = setTimeout(() => {
        clearTimeout(timer)
        scrollBottom()
      }, 500)
      clearNewMessage()
    }
  },
  { deep: true }
)

async function load($state: any) {
  const data = await apiGetHistory(pageNum.value, pageSize.value, visitorId.value)
  if (data) {
    total.value = data.totalPage
    let list = data.dataList || []
    list = list.filter((item: any) => item.msgType !== 3)
    for (let i = 0; i < list.length; i++) {
      try {
        list[i].msgContent = JSON.parse(list[i].msgContent)
      } catch (e) {
        console.error(e)
        $state.error()
        return
      }
    }
    msgList.value.unshift(...list.reverse())
    if (pageNum.value === 1) {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        scrollBottom()
      }, 300)
    }
    pageNum.value++
    if (pageNum.value <= total.value) {
      $state.loaded()
    } else {
      $state.complete()
    }
  } else {
    $state.error()
  }
}

// 處理時間
function dealTime(time: string) {
  // 是否今日內
  const isDay = dayjs().isSame(dayjs(time), 'day')
  const isYear = dayjs().isSame(dayjs(time), 'year')
  if (isDay) {
    return dayjs(time).format('HH:mm')
  } else if (isYear) {
    return dayjs(time).format('MM-DD HH:mm')
  } else {
    return dayjs(time).format('YYYY-MM-DD HH:mm')
  }
}

function tip(msg: string) {
  showFailToast(msg)
}

function imageView(src: string) {
  showImagePreview([src])
}

function scrollBottom() {
  // 滾動條滑到最底部
  const el = document.querySelector('#messageList')
  if (!el) {
    return
  }
  el.scrollTo({
    top: el.scrollHeight,
    behavior: 'smooth',
  })
}

function closeModal() {
  emit('close')
}
</script>

<style scoped lang="scss">
.chat-box-container {
  @include scrollbar($width: 4px);
  padding: 12px;
  height: calc(100% - 160px);
}
.no-message {
  @extend .text-tips;
  padding: 6px 0;
  text-align: center;
  color: $text-color-assist;
}
.message-item {
  width: 100%;
  box-sizing: border-box;
  display: flex;

  .content {
    margin-top: 4px;
    word-break: break-all;
    padding: 10px;
    line-height: 1;
    background-color: $white;
    border-radius: 12px;
    white-space: break-spaces;
    position: relative;
    @extend .text-body;
    color: $text-color-default;

    img {
      width: 200px;
      height: 200px;
      border-radius: 12px;
      box-shadow: $shadow;
    }
    .fb-emoji {
      width: 20px !important;
      height: 20px !important;
    }
  }
  .other-content {
    padding: 0;
  }

  .avatar {
    width: 36px;
    height: 44px;
    flex-shrink: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.message-left {
  .avatar {
    margin-right: 8px;
  }
  .msg-error {
    position: absolute;
    right: -16px;
  }
}
.message-right {
  flex-direction: row-reverse;
  .avatar {
    margin-left: 8px;
  }
  .content {
    background-color: $primary-500;
    color: $white;
  }
  .msg-error {
    position: absolute;
    left: -16px;
  }
}
.content-container {
  max-width: calc(90% - 56px);
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  .time {
    margin-top: 4px;
    @extend .text-tips;
    color: #a0a5ad;
    display: flex;
  }
  .time-right {
    justify-content: flex-end;
  }
}
.video-container {
  width: 268px;
  height: 335px;
}
:deep(.van-image) {
  display: block;
}
</style>
